<template>
  <div class="article-box ">
    
   
    <div class="index-news-box">
      <h1 class="title">{{newsInfo.title}}</h1>
      <div v-html="newsInfo.info">
      </div>
      
      
    </div>

    
    
  </div>
</template>

<script>
import {selectNewsByIdOrName} from "../utils/api";
export default {
  components: {
    
  },
  data() {
    return {
      id:'',
      active: 1,
      total: 4,
      newsInfo:'',
      headerShow:true,
      showNav:true,
      navShow:true,
      wrapScroll: {},
      cpList:[
        {title:'财神尊1',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊2',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊3',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊4',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊5',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊6',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
        {title:'财神尊7',content:'经典珠宝的典范',img:require('@/assets/images/index/cp.png')},
      ]
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
    // setTimeout(()=>{
    //   this.initscroll();
    // },20)
    this.id = this.$route.query.id;
    this.selectNewsByIdOrName();
  },
  methods: {
    selectNewsByIdOrName(){
      selectNewsByIdOrName({
        id: this.id,
      })
      .then((res) => {
        
        this.newsInfo=res.data[0];
       
        
      })
    },
  },
};
</script>

<style scoped lang="scss">
.article-box{
  .title{
    font-size: 40px;
    margin-bottom: 20px;
  }
  .index-news-box{
    padding: 5vh 10%;
    background: #fff;
    line-height: 2;
  }
  
}
</style>
